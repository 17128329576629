import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import { datadogRum } from '@datadog/browser-rum';

// https://app.datadoghq.com/rum/performance-monitoring?query=%40application.id%3A5d159dd9-1d16-4584-85fe-cdc619fbc463&tab=overview&from_ts=1699287120648&to_ts=1699373520648&live=true
export default defineNuxtPlugin(({ isDev, $config }) => {
    if (isDev || !process.client || $config.DEPLOY_ENV === 'dev') return;

    datadogRum.init({
        applicationId: '5d159dd9-1d16-4584-85fe-cdc619fbc463',
        clientToken: 'pub427033390aae74ab8e0afdc7d6442300',
        site: 'datadoghq.com',
        service: 'es-onboarding',
        env: $config.DEPLOY_ENV,
        version: $config.VERSION,
        sampleRate: 100,
        premiumSampleRate: 100,
        trackInteractions: true,
        trackFrustrations: true,
        defaultPrivacyLevel: 'allow',
    });

    datadogRum.startSessionReplayRecording();
});
