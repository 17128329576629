const COMPONENT_NAMES = {
    // Ideally these go in the general order that the steps might appear
    POSTAL_CODE: 'PostalCode',
    SHOPPING_FOR: 'ShoppingFor',
    EV_CHARGER: 'EvCharger',
    PROPERTY_TYPE: 'PropertyType',
    BUSINESS_CONTACT: 'BusinessContact',
    OWNS_PROPERTY: 'OwnsProperty',
    OWNS_PROPERTY_COMMERCIAL: 'OwnsPropertyCommercial',
    TIME_TO_PURCHASE: 'TimeToPurchase',
    RENT_CRITERIA: 'RentCriteria',
    CSM_HANDOFF: 'CsmHandoff',
    SUSTAINABILITY: 'Sustainability',
    ELECTRICITY_BILL: 'ElectricityBill',
    SERVICE_ERROR: 'ServiceError',
    STORAGE_INTEREST: 'StorageInterest',
    STORAGE_REASON: 'StorageReason',
    ADDRESS: 'Address',
    ROOF_PIN: 'RoofPin',
    ROOF_PIN_HELP: 'RoofPinHelp',
    ROOF_AGE: 'RoofAge',
    REMOVE_TREES: 'RemoveTrees',
    ORGANIZATION_NAME: 'OrganizationName',
    NAME: 'Name',
    EMAIL: 'Email',
    PHONE: 'Phone',
    VERIFY_COMPLETE: 'VerifyComplete',
    SCHEDULE_CALL: 'ScheduleCall',
    HEAT_PUMPS_INSTALLER_SEARCH: 'HeatPumpsInstallerSearch',
    REQUEST_EMAIL: 'RequestEmail',
};

const CHOICE_VALUES = {
    HEATPUMP: 'heatpump',
    SOLAR_PV: 'solar-pv',
    EV_CHARGER: 'ev-charger',
    RESIDENTIAL: 'residential',
    COMMERCIAL: 'commercial',
    NONPROFIT: 'nonprofit',
    INTERESTED: 'interested',
    NOT_INTERESTED: 'not_interested',
    BACKUP: 'EBP',
    SAVINGS: 'UR',
    SELF_SUPPLY: 'SS',
    OLD_ROOF: 'more_than_20',
    NEW_ROOF: 'less_than_20',
    OLD_ROOF_REPLACE: 'more_than_20_replacing',
    YES_REMOVE: 'yes',
    NO_REMOVE: 'no',
    OWNER: 'owner',
    NON_OWNER: '',
    RENT_OTHER: 'shopping_someone_else',
    RENT_HOA: 'shopping_hoa_or_multicondo',
    RENT_SELF: 'rent_for_self',
    REPRESENT_OWNER: 'represent_owner',
    COMMERCIAL_OWNER: 'owner',
    COMMERCIAL_LEASE: 'commercial_lessee',
    WITHIN_MONTHS: 'next_few_months',
    WITHIN_YEAR: 'next_year',
    NOT_SURE: 'unsure',
    TRUE: 'true',
    FALSE: 'false',
    SCHEDULE_CALL: 'schedule_call',
    REQUEST_EMAIL: 'request_email',
    HP_MONOLITH: 'hp_monolith',
    SERVICE_ERROR: 'service_error',
};

export const FINAL_STEPS = [COMPONENT_NAMES.VERIFY_COMPLETE, COMPONENT_NAMES.CSM_HANDOFF];

export const CHOICE_STEP_CHANGES = {
    SHOPPING_FOR: {
        SOLAR_PV: [
            { EV_CHARGER: false },
            { PROPERTY_TYPE: true },
            { OWNS_PROPERTY: true },
            { CSM_HANDOFF: false },
            { STORAGE_INTEREST: true },
            { REMOVE_TREES: true },
            { ROOF_AGE: true },
            { ELECTRICITY_BILL: true },
            { ADDRESS: true },
            { ROOF_PIN: true },
            { NAME: true },
            { EMAIL: true },
            { VERIFY_COMPLETE: true },
        ],
        EV_CHARGER: [
            { EV_CHARGER: true },
            { PROPERTY_TYPE: false },
            { OWNS_PROPERTY: false },
            { OWNS_PROPERTY_COMMERCIAL: false },
            { TIME_TO_PURCHASE: false },
            { CSM_HANDOFF: false },
            { ORGANIZATION_NAME: false },
            { PHONE: false },
            { STORAGE_INTEREST: false },
            { REMOVE_TREES: false },
            { ROOF_AGE: false },
            { ELECTRICITY_BILL: false },
            { ADDRESS: false },
            { ROOF_PIN: false },
            { NAME: false },
            { EMAIL: false },
            { PHONE: false },
            { VERIFY_COMPLETE: false },
        ],
        HEATPUMP: [
            { EV_CHARGER: false },
            { PROPERTY_TYPE: false },
            { OWNS_PROPERTY: false },
            { OWNS_PROPERTY_COMMERCIAL: false },
            { TIME_TO_PURCHASE: false },
            { CSM_HANDOFF: false },
            { ORGANIZATION_NAME: false },
            { PHONE: false },
            { STORAGE_INTEREST: false },
            { REMOVE_TREES: false },
            { ROOF_AGE: false },
            { ELECTRICITY_BILL: false },
            { ADDRESS: false },
            { ROOF_PIN: false },
            { NAME: false },
            { EMAIL: false },
            { PHONE: false },
            { VERIFY_COMPLETE: false },
        ],
    },
    EV_CHARGER: {},
    PROPERTY_TYPE: {
        RESIDENTIAL: [
            { OWNS_PROPERTY: true },
            { OWNS_PROPERTY_COMMERCIAL: false },
            { TIME_TO_PURCHASE: false },
            { ORGANIZATION_NAME: false },
            { PHONE: false },
            { STORAGE_INTEREST: true },
            { REMOVE_TREES: true },
            { ROOF_AGE: true },
        ],
        COMMERCIAL: [
            { OWNS_PROPERTY: false },
            { OWNS_PROPERTY_COMMERCIAL: true },
            { TIME_TO_PURCHASE: true },
            { ORGANIZATION_NAME: true },
            { PHONE: true },
            { STORAGE_INTEREST: false },
            { REMOVE_TREES: false },
            { ROOF_AGE: false },
        ],
        NONPROFIT: [
            { OWNS_PROPERTY: false },
            { OWNS_PROPERTY_COMMERCIAL: true },
            { TIME_TO_PURCHASE: true },
            { ORGANIZATION_NAME: true },
            { PHONE: true },
            { STORAGE_INTEREST: false },
            { REMOVE_TREES: false },
            { ROOF_AGE: false },
        ],
    },

    OWNS_PROPERTY: {
        TRUE: [{ RENT_CRITERIA: false }],
        // Rent means a personal non-commercial lease, so the residential rent criteria step is shown
        FALSE: [{ RENT_CRITERIA: true }],
    },
    OWNS_PROPERTY_COMMERCIAL: {},
    TIME_TO_PURCHASE: {},
    RENT_CRITERIA: {
        RENT_OTHER: [
            { STORAGE_INTEREST: true },
            { REMOVE_TREES: true },
            { ROOF_AGE: true },
            { ELECTRICITY_BILL: true },
        ],
        RENT_HOA: [{ STORAGE_INTEREST: true }, { REMOVE_TREES: true }, { ROOF_AGE: true }, { ELECTRICITY_BILL: true }],
        RENT_SELF: [
            { STORAGE_INTEREST: false },
            { STORAGE_REASON: false },
            { REMOVE_TREES: false },
            { ROOF_AGE: false },
            { ELECTRICITY_BILL: false },
        ],
    },
    CSM_HANDOFF: {},
    ELECTRICITY_BILL: {},
    STORAGE_INTEREST: {
        INTERESTED: [{ STORAGE_REASON: true }],
        NOT_INTERESTED: [{ STORAGE_REASON: false }],
    },
    STORAGE_REASON: {},
    ADDRESS: {},
    ROOF_PIN: {},
    ROOF_AGE: {},
    REMOVE_TREES: {},
    ORGANIZATION_NAME: {},
    NAME: {},
    EMAIL: {},
};

const NUMERIC_CONSTANT_VALUES = {
    CO2_TONS_PER_KWH: 0.0007601,
    CO2_TREES_PER_KWH: 0.0176808,
    AVG_NATIONAL_ELECTRICITY_BILL: 23, // 23 cents/kwh
};

const SERVICE_LEVEL_VALUES = {
    FULL: 'full',
    LIMITED: 'limited',
    NONE: 'none',
};

const handler = {
    get(target, name) {
        if (name in target) {
            return target[name];
        }
        throw new Error(`invalid key: ${name}`);
    },
};

export const COMPONENTS = new Proxy(COMPONENT_NAMES, handler);
export const COMPONENT_COUNT = Object.keys(COMPONENT_NAMES).length;
export const CHOICES = new Proxy(CHOICE_VALUES, handler);
export const NUMERIC_CONSTANT = new Proxy(NUMERIC_CONSTANT_VALUES, handler);
export const SERVICE_LEVEL = new Proxy(SERVICE_LEVEL_VALUES, handler);
export const COMMERCIAL_PROPERTY_TYPES = [CHOICES.COMMERCIAL, CHOICES.NONPROFIT];

export const MARKET_URL = '/market/';
export const ES_SITE_MARKET_START_PATH = '/market/start/';

export const PROPERTY_OVERRIDE_COOKIE_NAME = 'mfdp_mvp_property_type_override';
const COOKIE_PATH = '/';
const MAX_AGE_IN_SECONDS = 15;
const SAME_SITE_RULE = 'lax';
export const PROPERTY_OVERRIDE_COOKIE_PARAMS = {
    path: COOKIE_PATH,
    maxAge: MAX_AGE_IN_SECONDS,
    sameSite: SAME_SITE_RULE,
};
export const ERROR_REASON = {
    EMAIL_EXISTS: 'EMAIL_EXISTS',
    SUBMISSION: 'SUBMISSION',
    COOKIE_AUTHORIZATION: 'COOKIE_AUTHORIZATION',
    NO_SERVICE: 'NO_SERVICE',
    UNKNOWN: 'UNKNOWN',
};

export const GOOGLE_MAPS_API_MAX_LOAD_TIME = 1500;

export const GOOGLE_MAPS_API_LIBRARIES = {
    CORE: 'core',
    MAPS: 'maps',
    MARKER: 'marker',
    PLACES: 'places',
};

export const HP_EA_PHONE_NUMBER = '(617) 284-4960';

// TODO: add NexStar zip codes here
export const NEXSTAR_ZIP_CODES = new Set([
    // ABC Plumbing
    '60007',
    '60010',
    '60002',
    '60103',
    '60005',
    '60009',
    '60012',
    '60014',
    '60124',
    '60016',
    '60137',
    '60017',
    '60143',
    '60154',
    '60018',
    '60169',
    '60019',
    '60175',
    '60181',
    '60020',
    '60185',
    '60188',
    '60190',
    '60193',
    '60195',
    '60025',
    '60410',
    '60026',
    '60029',
    '60030',
    '60031',
    '60033',
    '60037',
    '60040',
    '60041',
    '60042',
    '60043',
    '60044',
    '60045',
    '60046',
    '60047',
    '60048',
    '60050',
    '60053',
    '60056',
    '60061',
    '60062',
    '60064',
    '60068',
    '60069',
    '60070',
    '60072',
    '60073',
    '60074',
    '60076',
    '60077',
    '60078',
    '60079',
    '60081',
    '60082',
    '60083',
    '60084',
    '60085',
    '60087',
    '60088',
    '60090',
    '60091',
    '60093',
    '60095',
    '60096',
    '60097',
    '60098',
    '60099',
    '60100',
    '60102',
    '60106',
    '60119',
    '60130',
    '60131',
    '60140',
    '60152',
    '60153',
    '60155',
    '60156',
    '60160',
    '60164',
    '60165',
    '60170',
    '60171',
    '60176',
    '60180',
    '60183',
    '60201',
    '60202',
    '60203',
    '60301',
    '60304',
    '60108',
    '60120',
    '60133',
    '60173',
    '60403',
    '60415',
    '60422',
    '60429',
    '60431',
    '60435',
    '60440',
    '60443',
    '60447',
    '60451',
    '60453',
    '60463',
    '60465',
    '60477',
    '60480',
    '60487',
    '60491',
    '60503',
    '60505',
    '60510',
    '60514',
    '60516',
    '60521',
    '60525',
    '60532',
    '60539',
    '60542',
    '60544',
    '60546',
    '60555',
    '60559',
    '60561',
    '60564',
    '60567',
    '60586',
    '60004',
    '60013',
    '60015',
    '60021',
    '60022',
    '60035',
    '60051',
    '60060',
    '60065',
    '60067',
    '60071',
    '60089',
    '60104',
    '60105',
    '60110',
    '60125',
    '60136',
    '60142',
    '60162',
    '60163',
    '60191',
    '60302',
    '60305',
    '60402',
    '60428',
    '60445',
    '60455',
    '60456',
    '60457',
    '60458',
    '60459',
    '60471',
    '60501',
    '60519',
    '60526',
    '60534',
    '60601',
    '60602',
    '60603',
    '60605',
    '60606',
    '60607',
    '60608',
    '60609',
    '60610',
    '60611',
    '60613',
    '60614',
    '60615',
    '60616',
    '60618',
    '60622',
    '60625',
    '60626',
    '60630',
    '60631',
    '60634',
    '60638',
    '60639',
    '60640',
    '60641',
    '60642',
    '60643',
    '60645',
    '60646',
    '60647',
    '60651',
    '60652',
    '60653',
    '60654',
    '60655',
    '60656',
    '60657',
    '60659',
    '60660',
    '60661',
    '60664',
    '60689',
    '60691',
    '60706',
    '60707',
    '60712',
    '60714',
    '60804',
    '60805',
    // TR Miller
    '60478',
    '60803',
    '60404',
    '60412',
    '60418',
    '60423',
    '60439',
    '60446',
    '60448',
    '60490',
    '60504',
    '60515',
    '60452',
    '60523',
    '60543',
    '60545',
    '60554',
    '60558',
    '60560',
    '60462',
    '60585',
    '60467',
    '60464',
    '60482',
    '60430',
    '60008',
    '60101',
    '60107',
    '60118',
    '60123',
    '60126',
    '60134',
    '60139',
    '60148',
    '60157',
    '60172',
    '60174',
    '60177',
    '60184',
    '60187',
    '60189',
    '60192',
    '60194',
    '60197',
    '60432',
    '60441',
    '60502',
    '60506',
    '60513',
    '60517',
    '60527',
    '60538',
    '60540',
    '60563',
    '60565',
    '60199',
    '60416',
    '60421',
    '60433',
    '60434',
    '60444',
    '60474',
    '60507',
    '60522',
    '60541',
    '60572',
    '60598',
    '60935',
    // Rescue Air
    '75409',
    '75043',
    '76040',
    '75212',
    '75454',
    '75034',
    '75010',
    '76006',
    '75207',
    '75071',
    '76227',
    '75007',
    '75050',
    '75208',
    '75424',
    '75068',
    '75287',
    '75060',
    '75223',
    '75078',
    '75006',
    '75019',
    '75061',
    '75224',
    '75009',
    '76205',
    '75234',
    '75150',
    '75211',
    '75069',
    '76210',
    '75229',
    '75041',
    '75236',
    '75002',
    '75065',
    '75067',
    '75244',
    '75233',
    '75407',
    '76226',
    '76092',
    '75230',
    '75206',
    '75098',
    '75077',
    '76177',
    '75201',
    '75094',
    '75022',
    '76244',
    '75220',
    '75202',
    '75048',
    '76262',
    '76182',
    '75235',
    '75226',
    '75089',
    '75057',
    '76137',
    '75225',
    '75246',
    '75088',
    '75056',
    '76148',
    '75231',
    '75040',
    '75024',
    '76180',
    '75238',
    '75044',
    '75093',
    '76039',
    '75209',
    '75070',
    '75080',
    '76021',
    '75205',
    '75035',
    '75252',
    '76022',
    '75214',
    '75013',
    '75248',
    '76111',
    '75218',
    '75025',
    '75254',
    '76117',
    '75228',
    '75023',
    '75240',
    '76118',
    '75247',
    '75075',
    '75243',
    '76120',
    '75219',
    '75074',
    '75042',
    '76053',
    '75204',
    // My Plumber Plus
    '20861',
    '21215',
    '20817',
    '20814',
    '20816',
    '20841',
    '20833',
    '20866',
    '20818',
    '20815',
    '20871',
    '20740',
    '21044',
    '21045',
    '21046',
    '20872',
    '20855',
    '21075',
    '21042',
    '21043',
    '21702',
    '21703',
    '21701',
    '20759',
    '20882',
    '20879',
    '20877',
    '20896',
    '20874',
    '20876',
    '21740',
    '21754',
    '20895',
    '20886',
    '21773',
    '20878',
    '21774',
    '20832',
    '20837',
    '20854',
    '20850',
    '20852',
    '20853',
    '20902',
    '20906',
    '20901',
    '20905',
    '20904',
    '20910',
    '20903',
    '20912',
    '21740',
    '21741',
    '21742',
    '21749',
    '21403',
    '21401',
    '21409',
    '21012',
    '20705',
    '20710',
    '20720',
    '20716',
    '20715',
    '21114',
    '21032',
    '21037',
    '20743',
    '20765',
    '21054',
    '21061',
    '21060',
    '20769',
    '20770',
    '20776',
    '20783',
    '20782',
    '20785',
    '20781',
    '20794',
    '20706',
    '20707',
    '20708',
    '20723',
    '20724',
    '21108',
    '20721',
    '20712',
    '20784',
    '21113',
    '20737',
    '20851',
    '21144',
    '21146',
    '20607',
    '20613',
    '20616',
    '20748',
    '20623',
    '20747',
    '20744',
    '20637',
    '20640',
    '20711',
    '20745',
    '20746',
    '20772',
    '20774',
    '20603',
    '20601',
    '20602',
    '20695',
    '20735',
    '22310',
    '22309',
    '22304',
    '22314',
    '22306',
    '22308',
    '22301',
    '22311',
    '22312',
    '22305',
    '22302',
    '22307',
    '22303',
    '23024',
    '22443',
    '22025',
    '22026',
    '22060',
    '22407',
    '22408',
    '22405',
    '22406',
    '22401',
    '22403',
    '22485',
    '22315',
    '22508',
    '22079',
    '23093',
    '23117',
    '22125',
    '22546',
    '22551',
    '22553',
    '22153',
    '22152',
    '22150',
    '22554',
    '22556',
    '22172',
    '22193',
    '22192',
    '22191',
    '20106',
    '22003',
    '22207',
    '22204',
    '22205',
    '22206',
    '22201',
    '22213',
    '22202',
    '22203',
    '22209',
    '20119',
    '20124',
    '22712',
    '22714',
    '22715',
    '20136',
    '22433',
    '22701',
    '22718',
    '22030',
    '22033',
    '22032',
    '22031',
    '22151',
    '22039',
    '22043',
    '22041',
    '22042',
    '22046',
    '22044',
    '20155',
    '22942',
    '22726',
    '20110',
    '20112',
    '20109',
    '20111',
    '22728',
    '20181',
    '22960',
    '22734',
    '22735',
    '22542',
    '22737',
    '22740',
    '22742',
    '22567',
    '20187',
    '20186',
    '22015',
    '22625',
    '20148',
    '20147',
    '22611',
    '20135',
    '22620',
    '20120',
    '20121',
    '20151',
    '20152',
    '20143',
    '22027',
    '22630',
    '22066',
    '20158',
    '20169',
    '20171',
    '20170',
    '22640',
    '20176',
    '20175',
    '22642',
    '20180',
    '22835',
    '20115',
    '22101',
    '22102',
    '20117',
    '20118',
    '22645',
    '22646',
    '22842',
    '22124',
    '20129',
    '20165',
    '20132',
    '20191',
    '20194',
    '20190',
    '20141',
    '22655',
    '20164',
    '20166',
    '20198',
    '22182',
    '22180',
    '22181',
    '20197',
    '22663',
    '22602',
    '22603',
    '22601',
    '20105',
]);
