import { defineNuxtPlugin } from '@nuxtjs/composition-api';

// Code taken from Referral Rock's developer guide
// Ensures the Referral Rock SDK is served

export default defineNuxtPlugin(() => {
    /* eslint-disable */
    window.referralJS = window.referralJS !== null && window.referralJS !== undefined ? window.referralJS : {};
    window.referralJS.scriptConfig = {
        parameters: {
            src: '//energysageinc.referralrock.com/ReferralSdk/referral.js',
            transactionKey: '83f38b34-ed6e-4620-82a2-028e38c2a0a7',
        },
    };
    (function (f, r, n, d, b, y) {
        (b = f.createElement(r)), (y = f.getElementsByTagName(r)[0]);
        b.async = 1;
        b.src =
            n +
            '?referrer=' +
            encodeURIComponent(window.location.origin + window.location.pathname).replace(/[!'()*]/g, escape);
        b.id = 'RR_DIVID_V5';
        b.setAttribute('transactionKey', window.referralJS.scriptConfig.parameters.transactionKey);
        y.parentNode.insertBefore(b, y);
    })(document, 'script', window.referralJS.scriptConfig.parameters.src);
});
