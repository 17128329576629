import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import Vue from 'vue';
import Notifications from 'vue-notification';

/**
 * https://github.com/euvl/vue-notification
 */
export default defineNuxtPlugin(() => {
    Vue.use(Notifications);
});
